<mat-card-header class="between">
  <mat-card-title>Key Information</mat-card-title>

  @if (canEdit) {
    <button mat-flat-button routerLink="edit">Edit</button>
  } @else {
    <button mat-button routerLink="edit">View Details</button>
  }
</mat-card-header>

@if (tow.metadata) {
  <mat-card-content>
    @if (tow.metadata.status) {
      <div class="row">
        <p class="label">Status:</p>
        <app-status-chip [value]="finalised ? 'finalised' : tow.metadata.status" />
      </div>
    }

    @if (tow.metadata.validity) {
      <div class="row">
        <p class="label">Validity:</p>
        <p>{{ tow.metadata.validity }}</p>
      </div>
    }

    @if (tow.metadata.cpr) {
      <div class="row">
        <p class="label">CPR:</p>
        <a [routerLink]="['/cprs', tow.metadata.cpr._id]">{{ tow.metadata.cpr.oldCprId }}</a>
      </div>
    }

    @if (tow.metadata.month || tow.metadata.year) {
      <div class="row">
        <p class="label">Date:</p>
        <p>
          @if (tow.metadata.month) {
            {{ towsService.toDate(tow) | date: 'MMM' : 'UTC' }}
          }
          @if (tow.metadata.year) {
            {{ towsService.toDate(tow) | date: 'yyyy' : 'UTC' }}
          }
        </p>
      </div>
    }

    @if (tow.metadata.route) {
      <div class="row">
        <p class="label">Route:</p>
        <a [routerLink]="['/routes', tow.metadata.route._id]">
          {{ tow.metadata.route.oldRouteComplexId }}
          @if (tow.metadata.route.fromPort && tow.metadata.route.toPort) {
            ({{ tow.metadata.route.fromPort }} -> {{ tow.metadata.route.toPort }})
          }
        </a>
      </div>
    }

    @if (tow.metadata.ship) {
      <div class="row">
        <p class="label">Ship:</p>
        <a [routerLink]="['/ships', tow.metadata.ship._id]">{{ tow.metadata.ship.name }}</a>
      </div>
    }

    @if (tow.metadata.message) {
      <div class="row">
        <p class="label">Comments:</p>
        <p>{{ tow.metadata.message }}</p>
      </div>
    }
  </mat-card-content>
}
