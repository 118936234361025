import { Component, Input } from '@angular/core';
import { FiltersChannel, IItemsTableOptions, ITableDataSource, SessionService } from '@suvo-bi-lib';
import { TableComponent } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/components/table/table.component';
import { ITablePagination } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-pagination.interface';
import { ITableSort } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { IFilters } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/filters/filters.interface';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { TowsService } from '../../service/tows.service';

@Component({
  selector: 'app-tows-table',
  templateUrl: './tows-table.component.html',
  styleUrls: ['./tows-table.component.scss'],
})
export class TowsTableComponent extends TableComponent<IRecordingEvent<'tow'>, string> {
  readonly headings = [
    'metadata.oldTowBaseId',
    'metadata.ship',
    'metadata.status',
    'metadata.year',
    'metadata.validity',
    'metadata.message',
    'updatedAt',
  ];
  readonly widths = [24, 8, 6, 28, 8, 8, 18];

  @Input() filtersChannel?: FiltersChannel;
  @Input() options: IItemsTableOptions;
  @Input() dontCache = false;
  @Input() tableAlias: string;

  pageSize = this.options?.defaultPageSize || 15;
  canSort = false;

  constructor(
    sessionService: SessionService,
    readonly towsService: TowsService,
  ) {
    super(sessionService);
  }

  /**
   * This override disables saved sort state in this table because it isn't working well.
   * (It doesn't store the `metadata.` part.)
   *
   * Instead it will always init with default sort
   *
   * @author Aidan
   */
  ngAfterViewInit() {
    try {
      const savedState = JSON.parse(sessionStorage.getItem(this.tableAlias));
      delete savedState.sort;
      sessionStorage.setItem(this.tableAlias, JSON.stringify(savedState));
    } catch (e) {}

    return super.ngAfterViewInit();
  }

  protected getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string,
  ): Promise<ITableDataSource<IRecordingEvent<'tow'>>> {
    return this.towsService.getPaginated('', {
      filters,
      sort,
      pagination,
      search,
    });
  }
}
