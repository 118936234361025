<form>
  <input
    #dayInput
    name="day"
    type="number"
    [(ngModel)]="day"
    placeholder="DD"
    required
    min="1"
    max="31"
    (keydown)="onKeyDown($event, 'start')"
  />
  <span>/</span>
  <input
    name="month"
    type="number"
    [(ngModel)]="month"
    placeholder="MM"
    required
    min="1"
    max="12"
    (keydown)="onKeyDown($event)"
  />
  <span>/</span>
  <input
    name="year"
    type="number"
    [(ngModel)]="year"
    placeholder="YYYY"
    required
    min="1900"
    max="3000"
    (keydown)="onKeyDown($event)"
  />

  <span>&nbsp;&nbsp;</span>

  <input
    name="hour"
    type="number"
    [(ngModel)]="hour"
    placeholder="HH"
    required
    min="0"
    max="23"
    (keydown)="onKeyDown($event)"
  />
  <span>:</span>
  <input
    name="min"
    type="number"
    [(ngModel)]="min"
    placeholder="mm"
    required
    min="0"
    max="59"
    (keydown)="onKeyDown($event, 'end')"
  />
</form>
