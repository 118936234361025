import { ExportVesselTrackResult } from '../../../marine-traffic/export-vessel-track-result';

export const PLACEHOLDER_AIS_RESPONSE: ExportVesselTrackResult = [
  [
    '239982500',
    '5',
    '0',
    '23.726880',
    '37.878850',
    '0',
    '320',
    '2021-02-08T12:57:01.000Z',
    '4317723',
  ],
  [
    '249032000',
    '15',
    '2',
    '23.548990',
    '37.903030',
    '160',
    '160',
    '2021-02-08T12:57:05.000Z',
    '362849',
  ],
];
