import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { TowLogTableComponent } from './components/tow-log-table/tow-log-table.component';
import { TowLogService } from './tow-log.service';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [TowLogTableComponent],
  providers: [TowLogService],
  exports: [TowLogTableComponent],
})
export class TowLogModule {}
