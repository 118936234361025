<app-header>
  @if (!towLog?.count && !samples?.count) {
    <button mat-flat-button (click)="onDelete()">Delete</button>
  }

  @if (tow.metadata.status !== 'finalised') {
    <button
      mat-flat-button
      (click)="finaliseTow()"
      [checkPermission]="['recordingEvent', 'finalise']"
    >
      Finalise Tow
    </button>
  }

  <a mat-flat-button routerLink="print">Print</a>
</app-header>

<div class="grid">
  <div>
    <mat-card class="info-tile">
      <app-tow-info-tile [tow]="tow" />
    </mat-card>
    <br />

    <app-tow-log-tile class="tow-log-tile" mode="simple" [tow]="tow" [towLog]="towLog" />
  </div>

  @if (showMap) {
    <app-tow-log-map-tile class="map-tile" height="100%" [tow]="tow" [towLog]="towLog" />
  } @else {
    <mat-card class="map-tile" style="opacity: 50%">
      <p style="text-align: center; margin: auto">Map unavailable.</p>
    </mat-card>
  }

  <app-tow-samples-tile style="grid-column-end: span 2" mode="simple" [tow]="tow" />
</div>
