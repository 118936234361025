import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  template: `
    <div>
      <app-pci-colour
        *ngFor="let option of options"
        [value]="option"
        showLabel="none"
        (click)="select(option)"
      ></app-pci-colour>
    </div>
  `,
  styles: [
    `
      div {
        display: flex;
        flex-flow: row wrap;
        gap: 6px;
      }

      app-pci-colour {
        cursor: pointer;
      }
    `,
  ],
})
export class PciColourEditorComponent implements ICellEditorAngularComp {
  params: ICellEditorParams;
  value: number;

  readonly options = [0, 1, 2, 6.5];

  agInit(params: ICellEditorParams) {
    this.params = params;
  }

  getValue() {
    return this.value;
  }

  select(value: number) {
    this.value = value;
    this.params.api.stopEditing();
  }
}
