import { Component, Input } from '@angular/core';
import { Filters, FiltersChannel, ITableDataSource } from '@suvo-bi-lib';
import { latLng, Layer, MapOptions } from 'leaflet';
import { Subject, takeUntil } from 'rxjs';
import { ITableSortDirection } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { MapService } from '../../../maps/map.service';
import { BASE_LAYERS } from '../../constants/map-constants';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { TowsService } from '../../service/tows.service';

@Component({
  selector: 'app-tows-map',
  template: `
    <p>Most recent {{ returnedResultCount }} tows of {{ totalResultCount }} are shown.</p>

    <div
      style="height: calc(100vh - 310px)"
      leaflet
      [leafletOptions]="mapOptions"
      [leafletBaseLayers]="baseLayers"
      [leafletLayers]="layers"
    ></div>

    @if (returnedResultWithEventsCount < returnedResultCount) {
      <br />

      <mat-card>
        <mat-card-header>
          <mat-card-title>Note</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li>
              Some or all of the filtered tows have no tow log and thus will not appear on the map.
            </li>
            <li>
              Tow logs that cross the antimeridian have been temporarily hidden due to an issue with
              Leaflet.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    }
  `,
})
export class TowsMapComponent {
  private readonly unsubscribe$ = new Subject<void>();

  @Input() filtersChannel?: FiltersChannel;
  @Input() height: string;

  readonly mapOptions: MapOptions = {
    center: latLng(50.37, -4.14),
    zoom: 3,
  };

  readonly baseLayers = BASE_LAYERS;

  /** The total number of results that match the filter. */
  totalResultCount = 0;
  /** The number of results actually returned (we've limited it on the backend). */
  returnedResultCount = 0;
  /** The number of results actually returned that have a tow log (thus will appear on the map). */
  returnedResultWithEventsCount = 0;

  layers: Layer[];

  constructor(
    private readonly map: MapService,
    private readonly tows: TowsService,
  ) {}

  ngOnInit() {
    this.filtersChannel.filters.pipe(takeUntil(this.unsubscribe$)).subscribe(async (filters) => {
      const tows = await this.fetch(filters);
      this.processResponse(tows);
    });
  }

  fetch(filters?: Filters) {
    return this.tows.getPaginated(
      '',
      {
        filters,
        sort: { direction: ITableSortDirection.Descending, property: 'updatedAt' },
      },
      true,
    );
  }

  processResponse(tows: ITableDataSource<IRecordingEvent<'tow'>>) {
    this.totalResultCount = tows.count;
    this.returnedResultCount = tows.data.length;
    this.returnedResultWithEventsCount = tows.data.reduce(
      (sum, next) => (next.events?.length ? sum + 1 : sum),
      0,
    );
    const layers = [];
    for (const tow of tows.data) {
      const plot = this.map
        .getTowLogPlot(tow.events)
        ?.eachLayer((layer) => layer.bindTooltip(tow.metadata.oldTowBaseId));
      if (plot) {
        layers.push(plot);
      }
    }
    this.layers = layers;
  }
}
