import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute } from '@angular/router';
import {
  CellEditingStoppedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  SizeColumnsToContentStrategy,
} from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeneralMessageService } from '../../../../../shared/services/general-message.service';
import { ISample } from '../../../samples/interface/sample.interface';
import { SamplesService } from '../../../samples/service/samples.service';
import { TowLogService } from '../../../tow-log/tow-log.service';
import { ICprUser } from '../../../users/interface/user.interface';
import { UsersService } from '../../../users/service/users.service';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { AnalystEditorComponent } from './analyst-editor.component';
import { AnalystRendererComponent } from './analyst-renderer.component';
import { PciColourEditorComponent } from './pci-colour-editor.component';
import { PciColourRendererComponent } from './pci-colour-renderer.component';

@Component({
  selector: 'app-tow-sample-allocation-screen',
  templateUrl: './tow-sample-allocation-screen.component.html',
  styleUrls: ['./tow-sample-allocation-screen.component.scss'],
})
export class TowSampleAllocationScreenComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  tow: IRecordingEvent<'tow'>;
  rowData: Partial<ISample>[];
  analysts: ICprUser[];
  columnDefs: ColDef[];
  autoSizeStrategy: SizeColumnsToContentStrategy;
  gridOptions: any = {
    singleClickEdit: true,
  };

  loadingData = true;

  readonly defaultColDef: ColDef = {
    sortable: false,
  };

  edit = false;
  changesMade = false;
  permission = new Set<'allocate' | 'analyse'>();
  initialStringifiedValue: string;

  gridApi: GridApi<ISample>;

  constructor(
    private readonly fireAuth: AngularFireAuth,
    private readonly route: ActivatedRoute,
    private readonly sampleService: SamplesService,
    private readonly towLogs: TowLogService,
    private readonly userService: UsersService,
    private readonly toast: GeneralMessageService,
  ) {}

  ngOnInit() {
    this.fireAuth.user.pipe(takeUntil(this.unsubscribe$)).subscribe(async (user) => {
      const token = await user.getIdTokenResult();
      if (token.claims.context.permissions['sample']) {
        for (const scope of ['allocate', 'analyse', 'finalise']) {
          if (token.claims.context.permissions['sample'].includes(scope)) {
            this.permission.add(scope as 'allocate' | 'analyse');
          }
        }
      }
    });

    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(({ data }) => {
      this.tow = data;
      this.pull();
    });

    this.userService.analysts.then((analysts) => {
      if (analysts) {
        this.analysts = analysts;
        this.generateColumnDefs();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private pull() {
    this.sampleService
      .getPaginated('', { simpleFilters: { towId: `ObjectId:${this.tow._id}` } })
      .then((samples) => {
        this.initialStringifiedValue = JSON.stringify(samples.data);
        this.reset();
        this.loadingData = false;
      });
  }

  private generateColumnDefs() {
    this.columnDefs = [
      {
        field: 'sampleNo',
        headerName: 'Sample no.',
        sort: 'asc',
        checkboxSelection: (params) => !params.data.finalisedAt && this.edit,
        headerCheckboxSelection: this.edit,
        cellClassRules: { finalised: (params) => !!params.data.finalisedAt },
      },
      {
        field: 'sampleLatitude',
        headerName: 'Latitude',
        cellRenderer: ({ value }) => this.towLogs.getDegreesMinutesString(value, 'lat'),
      },
      {
        field: 'sampleLongitude',
        headerName: 'Longitude',
        cellRenderer: ({ value }) => this.towLogs.getDegreesMinutesString(value, 'lng'),
      },
      {
        field: 'sampleMidpointDate',
        headerName: 'Midpoint date',
        valueFormatter: ({ value }) => new Date(value).toLocaleString('en-GB'),
      },
      {
        field: 'chlorophyllIndex',
        headerName: 'PCI',
        width: 120,
        editable: (params) => {
          return !params.data.finalisedAt && this.edit && this.permission.has('analyse');
        },
        cellEditor: PciColourEditorComponent,
        cellRenderer: PciColourRendererComponent,
      },
      {
        field: 'userId',
        headerName: 'Analyst',
        editable: (params) => {
          return !params.data.finalisedAt && this.edit && this.permission.has('allocate');
        },
        cellEditor: AnalystEditorComponent,
        cellRenderer: AnalystRendererComponent,
        valueFormatter: ({ value }) => {
          const analyst = this.analysts.find((user) => user._id === value);
          return `${analyst?.legacyAnalystId ?? 'N/A'}: ${analyst?.displayName ?? analyst?.name ?? 'N/A'}`;
        },
        flex: 1,
      },
    ];

    this.autoSizeStrategy = {
      type: 'fitCellContents',
      // Any cols without a size goal will autosize to cell contents
      colIds: this.columnDefs.filter((def) => !def.flex && !def.width).map(({ field }) => field),
    };
  }

  cancelEdit() {
    this.edit = false;
    this.generateColumnDefs();
    this.reset();
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    const selected = event.api.getSelectedNodes();
    if (selected.find((node) => node === event.node)) {
      for (const node of selected) {
        if (!node.data.finalisedAt) {
          node.setDataValue(event.column, event.newValue);
        }
      }
    }
    this.changesMade = JSON.stringify(this.rowData) !== this.initialStringifiedValue;
  }

  reset() {
    this.rowData = JSON.parse(this.initialStringifiedValue);
    this.changesMade = false;
  }

  async save() {
    const promises = [];
    const unfinalised = this.rowData.filter((sample) => !sample.finalisedAt);
    if (this.permission.has('analyse')) {
      const data = unfinalised.map(({ _id, chlorophyllIndex }) => ({ _id, chlorophyllIndex }));
      promises.push(this.sampleService.analyseMany(data));
    }
    if (this.permission.has('allocate')) {
      const data = unfinalised.map(({ _id, userId }) => ({ _id, userId }));
      promises.push(this.sampleService.allocateMany(data));
    }
    await Promise.allSettled(promises);
    this.initialStringifiedValue = JSON.stringify(this.rowData);
    this.cancelEdit();
  }

  startEdit() {
    this.edit = true;
    this.generateColumnDefs();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  onAutoAllocate() {
    if (!this.permission.has('allocate')) {
      this.toast.error('Your account has not been granted permission to allocate.');
      return;
    }

    const samples = this.gridApi
      .getSelectedRows()
      .filter((sample) => !sample.finalisedAt)
      .map((sample) => sample._id);

    if (!samples?.length) {
      this.toast.error('You must make a selection first.');
      return;
    }

    this.sampleService.autoAllocate(this.tow._id, samples, () => this.pull());
  }
}
