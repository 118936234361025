import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface Params extends ICellRendererParams {
  isEditMode: () => boolean;
  onDelete: (row: number) => unknown;
}

@Component({
  selector: 'app-tow-log-data-table-actions',
  template: `
    @if (edit) {
      <button mat-icon-button (click)="onDelete(rowIndex)">
        <mat-icon>delete</mat-icon>
      </button>
    }
  `,
})
export class TowLogDataTableActionsRendererComponent implements ICellRendererAngularComp {
  edit: boolean;
  rowIndex: number;

  onDelete: (rowIndex: number) => unknown;

  agInit(params: Params) {
    this.edit = params.isEditMode();
    this.rowIndex = params.node.rowIndex;
    this.onDelete = params.onDelete;
  }

  refresh(params: Params) {
    this.agInit(params);
    return true;
  }
}
