<div style="display: flex" *ngIf="tow && count == 0">
  <button mat-flat-button color="primary" style="margin: auto" routerLink="log">
    Create tow log
  </button>
</div>

<table
  *ngIf="isLoading || count > 0"
  mat-table
  [dataSource]="dataSource$"
  matSort
  [defaultSort]="defaultSort"
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="listDropped($event)"
>
  <ng-container matColumnDef="eventDate">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      <span *ngIf="!isLoading">Date & time (UTC)</span>
    </th>
    <td mat-cell *matCellDef="let item">
      <span>{{ item.eventDate | date: 'medium' : 'UTC' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      <span *ngIf="!isLoading">Type</span>
    </th>
    <td mat-cell *matCellDef="let item">
      <span style="text-transform: capitalize">{{
        item.eventCode.toLowerCase().replace('_', ' ')
      }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="lat">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      <span *ngIf="!isLoading">Latitude</span>
    </th>
    <td mat-cell *matCellDef="let item">
      <span>{{ towLogEvents.getDegreesMinutesString(item.eventLatitude, 'lat') }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="long">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      <span *ngIf="!isLoading">Longitude</span>
    </th>
    <td mat-cell *matCellDef="let item">
      <span>{{ towLogEvents.getDegreesMinutesString(item.eventLongitude, 'lng') }}</span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="headings"></tr>
  <tr mat-row class="row" [class.hidden]="isLoading" *matRowDef="let row; columns: headings"></tr>

  <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
    <td class="mat-cell" [attr.colspan]="headings.length">
      <span>No matching data.</span>
    </td>
  </tr>
</table>

<ng-container *ngIf="isLoading">
  <suvo-bi-skeleton-table-rows
    [rowCount]="pageSize"
    [columnWidths]="widths"
  ></suvo-bi-skeleton-table-rows>
</ng-container>

<!-- <mat-paginator
  *ngIf="count > 0"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
></mat-paginator> -->

<p *ngIf="count > pageSize">
  <i>Only showing the first {{ pageSize }} log events</i>
</p>
