import { tileLayer } from 'leaflet';

export const LAYER_BLUE_MARBLE = tileLayer(
  '//gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default/2024-06-12/GoogleMapsCompatible_Level8/{z}/{y}/{x}.jpeg',
  {
    maxNativeZoom: 8,
    maxZoom: 9,
    tileSize: 256,
    subdomains: 'abc',
    bounds: [
      [-85.0511287776, -179.999999975],
      [85.0511287776, 179.999999975],
    ],
    attribution:
      '<a href="https://wiki.earthdata.nasa.gov/display/GIBS">' +
      'NASA EOSDIS GIBS</a>&nbsp;&nbsp;&nbsp;' +
      '<a href="https://github.com/nasa-gibs/web-examples/blob/main/examples/leaflet/webmercator-epsg3857.js">' +
      'View Source' +
      '</a>',
  },
);

export const LAYER_GEBCO = tileLayer.wms('https://geoserver.mba.ac.uk/gacs_base/wms', {
  layers: 'GEBCO_LATEST_2',
});

export const BASE_LAYERS = {
  'Blue Marble': LAYER_BLUE_MARBLE,
  GEBCO: LAYER_GEBCO,
};
