import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { FiltersChannel, FiltersChannelsService, FiltersSidenavService } from '@suvo-bi-lib';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
  styles: `
    .controls {
      display: flex;
    }
  `,
  template: `
    <mat-sidenav-container autosize>
      <mat-sidenav
        #filtersSidenav
        class="right-sidenav"
        mode="side"
        autoFocus="false"
        fixedInViewport="true"
        position="end"
        [opened]="filtersSidenavOpenedByDefault"
        [mode]="filtersSidenavMode"
      >
        <suvo-bi-filters-sidenav [filtersChannel]="filtersChannel" #filtersSidenav />
      </mat-sidenav>

      <mat-sidenav-content>
        <app-header>
          <div class="controls">
            <mat-button-toggle-group [(value)]="activeView">
              <mat-button-toggle value="table">
                <mat-icon>list</mat-icon>
                <span>List</span>
              </mat-button-toggle>
              <mat-button-toggle value="map">
                <mat-icon>map</mat-icon>
                <span>Map</span>
              </mat-button-toggle>
            </mat-button-toggle-group>

            @if (filtersChannel) {
              <suvo-bi-active-filters-widget [filtersChannel]="filtersChannel" />
            }
          </div>
        </app-header>

        <div style="padding: 0 var(--global-padding)">
          @if (activeView === 'table') {
            <app-tows-table tableAlias="tows" [filtersChannel]="filtersChannel" />
          }

          @if (activeView === 'map') {
            <app-tows-map [filtersChannel]="filtersChannel" />
          }
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
})
export class TowListScreenComponent implements AfterViewInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  readonly filtersChannel: FiltersChannel;

  @ViewChild('filtersSidenav') filtersSidenav: MatSidenav;

  filtersSidenavOpenedByDefault = false;
  filtersSidenavMode = 'side';

  activeView: 'table' | 'map' = 'table';

  constructor(
    filtersChannelService: FiltersChannelsService,

    private breakpointObserver: BreakpointObserver,
    private filtersSidenavService: FiltersSidenavService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.filtersChannel = filtersChannelService.getFiltersChannel('tows', null, null, 'GENERAL');

    if (
      this.breakpointObserver.isMatched([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
    ) {
      this.filtersSidenavOpenedByDefault = false;
      this.filtersSidenavService.setActive(false, false);
    } else {
      this.filtersSidenavOpenedByDefault = true;
      this.filtersSidenavService.setActive(true, true);
    }

    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((result) => {
        if (result.matches) {
          this.filtersSidenavMode = 'over';
        } else {
          this.filtersSidenavMode = 'side';
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  ngAfterViewInit() {
    this.filtersSidenavService.sidenavSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: any) => {
        setTimeout(() => {
          if (this.filtersSidenav) {
            state.open ? this.filtersSidenav.open() : this.filtersSidenav.close();
          }
        });
      });

    if (this.filtersSidenav) {
      this.filtersSidenav.openedChange
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((filtersSidenavOpen) => {
          setTimeout(() => {
            if (this.filtersSidenavService?.sidenavOpen != filtersSidenavOpen) {
              this.filtersSidenavService?.setOpen(filtersSidenavOpen);
            }
          });
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
