import { Component, Input, OnChanges } from '@angular/core';
import { PermissionsService } from '../../../../../permissions/permissions.service';
import { IRecordingEvent } from '../../../../interface/recording-event.interface';
import { TowsService } from '../../../../service/tows.service';

@Component({
  selector: 'app-tow-info-tile',
  templateUrl: './tow-info-tile.component.html',
  styleUrl: './tow-info-tile.component.scss',
})
export class TowInfoTileComponent implements OnChanges {
  @Input() tow: IRecordingEvent<'tow'>;

  canEdit = false;
  finalised = false;
  hasPermission = false;

  constructor(
    permissions: PermissionsService,
    readonly towsService: TowsService, // `.toDate` is used in component
  ) {
    permissions.hasPermission('recordingEvent', 'edit').then((b) => (this.hasPermission = b));
  }

  ngOnChanges() {
    this.finalised = this.tow.metadata.status === 'finalised';
    this.canEdit = this.hasPermission && !this.finalised;
  }
}
