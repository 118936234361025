import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ITableDataSource } from '@suvo-bi-lib';
import { take } from 'rxjs/operators';
import { TowFinaliseDialogComponent } from '../../../samples/components/tow-finalise-dialog/tow-finalise-dialog.component';
import { ISample } from '../../../samples/interface/sample.interface';
import { SamplesService } from '../../../samples/service/samples.service';
import { TowLogService } from '../../../tow-log/tow-log.service';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { ITowLogEvent } from '../../interface/tow-log-event.interface';
import { TowsService } from '../../service/tows.service';

@Component({
  templateUrl: './tow-overview-screen.component.html',
  styleUrl: './tow-overview-screen.component.scss',
})
export class TowOverviewScreenComponent {
  tow: IRecordingEvent<'tow'>;
  towLog: ITableDataSource<ITowLogEvent>;
  samples: ITableDataSource<ISample>;

  showMap = false;

  constructor(
    route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly sampleService: SamplesService,
    private readonly towLogService: TowLogService,
    private readonly towService: TowsService,
  ) {
    route.data.pipe(take(1)).subscribe(({ data }) => {
      this.tow = data;
      let options = { simpleFilters: { towId: `ObjectId:${this.tow._id}` } };
      this.towLogService.getPaginated('', options).then((towLog) => {
        this.towLog = towLog;
        this.showMap = this.towLog.count > 0;
      });
      this.sampleService.getPaginated('', options).then((samples) => {
        this.samples = samples;
      });
    });
  }

  onDelete() {
    this.towService.delete(this.tow._id, () => this.router.navigate(['/tows']));
  }

  finaliseTow() {
    this.dialog.open(TowFinaliseDialogComponent, {
      data: {
        towId: this.tow._id,
        onConfirm: () => {
          this.tow.metadata.status = 'finalised';
        },
      },
    });
  }
}
