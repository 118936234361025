import { Component, Input, OnInit } from '@angular/core';
import { ITableDataSource, SessionService } from '@suvo-bi-lib';
import { TableComponent } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/components/table/table.component';
import { IFilters } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/filters/filters.interface';
import { ITablePagination } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-pagination.interface';
import { ITableSort } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { IRecordingEvent } from '../../../tows/interface/recording-event.interface';
import { ITowLogEvent } from '../../../tows/interface/tow-log-event.interface';
import { TowLogService } from '../../tow-log.service';

@Component({
  selector: 'app-tow-log-table',
  templateUrl: './tow-log-table.component.html',
  styleUrls: ['./tow-log-table.component.scss'],
})
export class TowLogTableComponent extends TableComponent<ITowLogEvent, string> implements OnInit {
  readonly headings = ['eventDate', 'type', 'lat', 'long'];
  readonly widths = [10, 10, 10, 10, 10, 10, 10, 30];

  @Input() tow: IRecordingEvent<'tow'>;

  pageSize = 1;
  canSort = false;
  count: number;

  defaultSort: any = { active: 'eventDate', direction: 'asc' };

  constructor(
    sessionService: SessionService,
    readonly towLogEvents: TowLogService,
  ) {
    super(sessionService);
  }

  protected async getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string,
  ): Promise<ITableDataSource<ITowLogEvent>> {
    const result = await this.towLogEvents.getPaginated('', {
      filters,
      sort,
      pagination,
      search,
      simpleFilters: { towId: `ObjectId:${this.tow._id}` },
    });
    this.count = result.count;
    return result;
  }
}
