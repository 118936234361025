import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UsersModule } from '../../../users/users.module';

@Component({
  standalone: true,
  imports: [UsersModule],
  template: '@if (userId) { <app-user-avatar [userId]="userId" /> }',
})
export class AnalystRendererComponent implements ICellRendererAngularComp {
  userId: string;

  agInit(params: ICellRendererParams) {
    this.userId = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.agInit(params);
    return true;
  }
}
