<mat-card>
  <mat-card-header class="between">
    <mat-card-title>Samples</mat-card-title>

    @if (count > 0) {
      <div class="links">
        <a mat-flat-button routerLink="samples/print">
          <mat-icon>print</mat-icon>
          <span>Print</span>
        </a>
        <a mat-flat-button routerLink="cutting-points">
          <mat-icon>content_cut</mat-icon>
          <span>View Cutting Points</span>
        </a>
        <a mat-flat-button routerLink="samples/allocate">
          <mat-icon>checklist</mat-icon>
          <span>Set PCI Colour & Allocate Samples</span>
        </a>
        <a mat-flat-button routerLink="samples">
          <mat-icon>grid_on</mat-icon>
          <span>View Analysis Overview</span>
        </a>
      </div>
    }
  </mat-card-header>

  <mat-card-content>
    <div>
      <mat-checkbox (change)="onMySamplesToggle($event)" [ngModel]="onlyMySamples">
        Only show samples allocated to you
      </mat-checkbox>
    </div>
    <table
      mat-table
      [dataSource]="dataSource$"
      matSort
      matSortDisableClear
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="listDropped($event)"
    >
      <ng-container matColumnDef="sampleNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">Sample no.</span>
        </th>
        <td mat-cell *matCellDef="let item">{{ item.sampleNo }}</td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">Analyst</span>
        </th>
        <td mat-cell *matCellDef="let item">
          <app-user-avatar [userId]="item.userId" />
        </td>
      </ng-container>

      <ng-container matColumnDef="chlorophyllIndex">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">PCI Colour</span>
        </th>
        <td mat-cell *matCellDef="let item">
          <app-pci-colour [value]="item.chlorophyllIndex" />
        </td>
      </ng-container>

      <ng-container matColumnDef="sampleLatitude">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">Latitude</span>
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.sampleLatitude | coord: 'lat' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="sampleLongitude">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">Longitude</span>
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.sampleLongitude | coord: 'lng' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="sampleMidpointDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">UTC Midpoint Date</span>
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.sampleMidpointDate | date: 'medium' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="button">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item" style="text-align: right">
          @if (!item.finalisedAt && item.userId === user?._id) {
            <button mat-button>Data Entry</button>
          }
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headings"></tr>
      <tr
        mat-row
        class="clickable"
        [class.hidden]="isLoading"
        *matRowDef="let row; columns: headings"
        [routerLink]="['samples', row._id]"
      ></tr>

      <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
        <td class="mat-cell" [attr.colspan]="headings.length">
          <!-- prettier-ignore -->
          <span>{{ onlyMySamples ? 'No matching data' : 'Unavailable until generated from tow log'}}</span>
        </td>
      </tr>
    </table>

    @if (isLoading) {
      <suvo-bi-skeleton-table-rows [rowCount]="15" [columnWidths]="widths" />
    }
  </mat-card-content>
</mat-card>
