import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ITableDataSource } from '@suvo-bi-lib';
import {
  CellDoubleClickedEvent,
  CellKeyDownEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  SizeColumnsToContentStrategy,
} from 'ag-grid-community';
import { CustomDateComponent } from '../../../../../shared/components/ag-custom-date-editor/custom-date.component';
import { PermissionsService } from '../../../permissions/permissions.service';
import { TowLogService } from '../../../tow-log/tow-log.service';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { ITowLogEvent } from '../../interface/tow-log-event.interface';
import { DegreesMinutesEditorComponent } from './degrees-minutes-editor.component';
import { TowLogDataTableActionsRendererComponent } from './tow-log-data-table-actions-renderer.component';

@Component({
  selector: 'app-tow-log-data-table',
  templateUrl: './tow-log-data-table.component.html',
  styleUrls: ['./tow-log-data-table.component.scss'],
})
export class TowLogDataTableComponent implements OnInit, OnChanges {
  @Input() tow: IRecordingEvent<'tow'>;
  @Input() towLog: ITableDataSource<ITowLogEvent>;
  @Input() cutsGenerated = true; // assume unless told otherwise

  @Output() towLogPushed = new EventEmitter();
  @Output() editModeState = new EventEmitter<boolean>();

  private gridAPI: GridApi;

  readonly columnDefs: ColDef[] = [
    {
      field: 'eventDate',
      headerName: 'Date & Time (UTC)',
      cellEditor: CustomDateComponent,
      minWidth: 334,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const timezoneOffset = date.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(date.valueOf() + timezoneOffset);
        return adjustedDate.toLocaleString('en-GB');
      },
    },
    {
      field: 'eventCode',
      headerName: 'Type',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['SHOOT', 'COURSE_CHANGE', 'HAUL', 'MIDSHOOT', 'MIDHAUL'] },
      refData: {
        SHOOT: 'Shoot',
        HAUL: 'Haul',
        COURSE_CHANGE: 'Course change',
        MIDHAUL: 'Mid haul',
        MIDSHOOT: 'Mid shoot',
      },
      minWidth: 160,
    },
    {
      field: 'eventLatitude',
      headerName: 'Latitude',
      cellEditor: DegreesMinutesEditorComponent,
      cellEditorParams: {
        direction: 'lat',
      },
      cellRenderer: ({ value }: ICellRendererParams) => {
        if (value) {
          return this.towLogs.getDegreesMinutesString(value, 'lat');
        }
      },
      minWidth: 150,
    },
    {
      field: 'eventLongitude',
      headerName: 'Longitude',
      cellEditor: DegreesMinutesEditorComponent,
      cellEditorParams: {
        direction: 'lng',
      },
      cellRenderer: ({ value }: ICellRendererParams) => {
        if (value) {
          return this.towLogs.getDegreesMinutesString(value, 'lng');
        }
      },
      minWidth: 150,
    },
    { field: 'course', editable: false, valueFormatter: ({ value }) => value?.toFixed(2) },
    { field: 'speed', editable: false, valueFormatter: ({ value }) => value?.toFixed(2) },
    { field: 'distance', editable: false, valueFormatter: ({ value }) => value?.toFixed(2) },
    { field: 'comments', flex: 1 },
    {
      cellRenderer: TowLogDataTableActionsRendererComponent,
      cellRendererParams: {
        isEditMode: (() => this.edit).bind(this),
        onDelete: this.onDelete.bind(this),
      },
      type: 'rightAligned',
      width: 90,
      editable: false,
    },
  ];

  readonly autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents',
    // Any cols without a size goal will autosize to cell contents
    colIds: this.columnDefs.filter((def) => !def.flex && !def.width).map(({ field }) => field),
  };

  edit = false;
  changesMade = false;
  hasPermission = false;
  defaultColDef: ColDef = {
    sortable: false,
  };
  gridOptions: GridOptions = {
    singleClickEdit: true,
  };
  initialStringifiedValue: string;
  defaultStringifiedValue: string;
  rowData: Partial<ITowLogEvent>[];

  constructor(
    private readonly permissions: PermissionsService,
    private readonly towLogs: TowLogService,
  ) {}

  async ngOnInit() {
    this.hasPermission = await this.permissions.hasPermission('towLog', 'edit');
    this.editModeState.emit(this.edit);
  }

  ngOnChanges() {
    if (this.towLog) {
      this.initialStringifiedValue = JSON.stringify(this.towLog.data);
      this.reset();
    }

    if (this.hasPermission && !this.cutsGenerated) {
      this.startEdit();
    }
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
  }

  onKeyDown(event: CellKeyDownEvent) {
    const keyEvent = event.event as KeyboardEvent;
    if (keyEvent.key === 'Enter' && keyEvent.shiftKey) {
      this.insertRow();
    }
  }

  onDoubleClick({ rowIndex, column }: CellDoubleClickedEvent) {
    if (!this.edit) {
      this.startEdit();
      setTimeout(() => {
        this.gridAPI.startEditingCell({ rowIndex, colKey: column });
      });
    }
  }

  cancelEdit() {
    this.edit = false;
    this.defaultColDef = { ...this.defaultColDef, editable: false };
    this.reset();
    this.editModeState.emit(this.edit);
  }

  checkChangesMade() {
    this.changesMade = JSON.stringify(this.rowData) !== this.initialStringifiedValue;
  }

  insertRow() {
    this.rowData = [
      ...this.rowData,
      { towId: this.tow._id, eventCode: this.rowData.length ? 'COURSE_CHANGE' : 'SHOOT' },
    ];
    this.checkChangesMade();
    setTimeout(() => {
      this.gridAPI.startEditingCell({ rowIndex: this.rowData.length - 1, colKey: 'eventDate' });
    });
  }

  reset() {
    if (this.initialStringifiedValue == this.defaultStringifiedValue) {
      this.rowData = [];
    } else {
      this.rowData = JSON.parse(this.initialStringifiedValue);
    }

    this.changesMade = false;
  }

  async save() {
    await this.towLogs.updateForTow(this.tow._id, this.rowData);
    this.initialStringifiedValue = JSON.stringify(this.rowData);
    this.cancelEdit();
    this.towLogPushed.emit();
  }

  startEdit() {
    this.edit = true;
    this.defaultColDef = { ...this.defaultColDef, editable: true };
    if (!this.rowData.length) {
      this.insertRow();
      this.defaultStringifiedValue = JSON.stringify(this.rowData);
    }
    this.initialStringifiedValue = JSON.stringify(this.rowData);
    this.editModeState.emit(this.edit);
  }

  onDelete(rowNumber: number) {
    this.rowData = this.rowData.filter((_, i) => i !== rowNumber);
    this.checkChangesMade();
  }
}
