import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { TDirection, TowLogService } from '../../../tow-log/tow-log.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <form>
      <input
        #initialFocusInput
        [(ngModel)]="degrees"
        (keydown)="onKeyDown($event, 'start')"
        type="number"
        name="degrees"
        placeholder="Degs"
        min="0"
        max="180"
        required
      />
      <input
        [(ngModel)]="minutes"
        (keydown)="onKeyDown($event)"
        type="number"
        name="minutes"
        placeholder="Mins"
        min="0"
        max="59"
        step="0.01"
        required
      />
      <input
        [(ngModel)]="direction"
        (keydown)="onKeyDown($event, 'end')"
        (input)="onDirectionInput()"
        name="direction"
        pattern="^(N|E|S|W)$"
        required
        style="width: 16px"
      />
    </form>
  `,
  styles: [
    `
      form {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
      }

      input {
        width: 36px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding: 4px 6px;

        &:invalid {
          &.ng-dirty {
            border-color: var(--warn-color);
          }
        }
      }
    `,
  ],
})
export class DegreesMinutesEditorComponent implements ICellEditorAngularComp {
  @ViewChild('initialFocusInput') initialFocusInput: ElementRef<HTMLInputElement>;

  private params!: ICellEditorParams;

  degrees: number;
  minutes: number;
  direction: TDirection;

  constructor(private readonly towLogs: TowLogService) {}

  agInit(params: ICellEditorParams & { direction: 'lat' | 'lng' }) {
    this.params = params;

    if (typeof this.params.value === 'number') {
      const [deg, min, dir] = this.towLogs.getDegreesMinutes(params.value, params.direction);
      this.degrees = deg;
      this.minutes = min;
      this.direction = dir;
    } else {
      this.direction = params.direction === 'lat' ? 'N' : 'E';
    }

    setTimeout(() => {
      this.initialFocusInput.nativeElement.focus();
    });
  }

  getValue() {
    if (!this.degrees && !this.minutes) {
      return;
    }

    let value = this.degrees ?? 0;

    if (this.minutes) {
      value += this.minutes / 60;
    }

    if (this.direction === 'S' || this.direction === 'W') {
      value = 0 - value;
    }

    return value;
  }

  /**
   * Stop propagation to AG Grid and stay within the HTML form.
   */
  onKeyDown(event: KeyboardEvent, position: 'start' | 'end' = null) {
    if (event.key === 'Tab') {
      if ((position === 'start' && event.shiftKey) || (position === 'end' && !event.shiftKey)) {
        return;
      }
      event.stopPropagation();
    }
  }

  onDirectionInput() {
    this.direction = this.direction.toUpperCase() as TDirection;
  }
}
