import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ExportVesselTrackOptions } from './export-vessel-track-options';
import { ExportVesselTrackResult } from './export-vessel-track-result';

@Injectable()
export class MarineTrafficApiService {
  constructor(private readonly http: HttpClient) {}

  vesselTrack(options: ExportVesselTrackOptions) {
    return this.http.post<ExportVesselTrackResult>(
      `${environment.cprServiceApi}marine-traffic/vessel-track`,
      options,
    );
  }
}
