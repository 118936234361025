import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '@suvo-bi-core';
import { ITableDataSource } from '@suvo-bi-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TowLogService } from '../../../tow-log/tow-log.service';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { ITowLogEvent } from '../../interface/tow-log-event.interface';

@Component({
  selector: 'app-tow-log-screen',
  template: `
    <app-header>
      <a mat-flat-button routerLink="print">Print</a>
    </app-header>

    <div
      style="display: flex; flex-flow: column; gap: var(--global-padding); margin: var(--global-padding)"
    >
      @if (showMap) {
        <app-tow-log-map-tile height="300px" [tow]="tow" [towLog]="towLog" />
      }

      @if (!loadingTowLog) {
        <app-tow-log-tile [tow]="tow" [towLog]="towLog" mode="full" (towLogPushed)="refreshMap()" />
      }
    </div>
  `,
})
export class TowLogScreenComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();
  tow: IRecordingEvent<'tow'>;
  towLog: ITableDataSource<ITowLogEvent>;
  showMap = false;

  loadingTowLog = true;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumb: BreadcrumbsService,
    private readonly towLogService: TowLogService,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(async ({ data }) => {
      this.tow = data;
      if (!this.tow) {
        this.router.navigate(['..'], { relativeTo: this.route });
        throw new Error('Empty result');
      }
      this.breadcrumb.setDynamicNameSwap('tow-id', this.tow.metadata?.oldTowBaseId ?? this.tow._id);
      this.refreshMap();
    });
  }

  async refreshMap() {
    this.towLog = await this.towLogService.getPaginated('', {
      simpleFilters: { towId: `ObjectId:${this.tow._id}` },
    });
    this.loadingTowLog = false;
    this.showMap = this.towLog.count > 0;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // TODO(fix): might need to listen for tow log pushed event and reload
}
