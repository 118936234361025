import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { GeneralMessageService } from '../../shared/services/general-message.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  private readonly successExclusionRegex = /filters|notification|unfinalise/i;
  private readonly errorExclusionRegex = /finalise/i;

  constructor(private readonly generalMessageService: GeneralMessageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (request.method !== 'GET' && !this.successExclusionRegex.test(request.url)) {
              this.generalMessageService.success('Updated successfully.');
            }
          }
        },
        error: (event: HttpEvent<any>) => {
          if (event instanceof HttpErrorResponse) {
            if (this.errorExclusionRegex.test(request.url)) {
              return;
            }

            let msg = 'Something went wrong.';

            if (event.error.message) {
              msg = event.error.message;
            } else if (event.status == 400 && request.method !== 'GET') {
              msg =
                "Something doesn't look right. Please check for any missing or invalid inputs and try again.";
            } else if (event.status == 403) {
              msg = 'Your account lacks the required permission(s) for this action.';
            } else if (event.status == 404 && request.method === 'GET') {
              msg = 'Resource could not be found.';
            }

            this.generalMessageService.error(msg, { duration: 6000 });
          }
        },
      }),
    );
  }
}
