import { Injectable } from '@angular/core';
import { circleMarker, Layer, layerGroup, polyline, Tooltip } from 'leaflet';
import { ITowLogEvent } from '../tows/interface/tow-log-event.interface';

@Injectable()
export class MapService {
  getTowLogPlot(towLog: ITowLogEvent[]) {
    if (this.checkForAntimeridianIssue(towLog)) {
      return;
    }

    const layers: Layer[] = [];

    if (towLog.length) {
      layers.push(...this.getTowLogMarkers(towLog));
    }

    if (towLog.length >= 2) {
      layers.push(this.getTowLogPolyline(towLog));
    }

    return layerGroup(layers);
  }

  getTowLogMarkers(towLog: ITowLogEvent[]) {
    return towLog.map((event) =>
      circleMarker(
        { lat: event.eventLatitude, lng: event.eventLongitude },
        { color: 'var(--primary-color)', radius: 1 },
      ),
    );
  }

  getTowLogPolyline(towLog: ITowLogEvent[]) {
    return polyline(
      towLog.map((event) => ({
        lat: event.eventLatitude,
        lng: event.eventLongitude,
      })),
      { color: 'var(--primary-color)', weight: 2 },
    );
  }

  /**
   * An issue with leaflet is if two points in a polyline cross the antimeridian,
   * the line won't wrap around and instead will draw horizontally across the entire map.
   */
  checkForAntimeridianIssue(towLog: ITowLogEvent[]) {
    let prev = towLog[0];
    for (const event of towLog.slice(1)) {
      if (Math.abs(prev.eventLongitude - event.eventLongitude) > 180) {
        return true;
      }
      prev = event;
    }
    return false;
  }
}
