import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({ template: '<app-pci-colour [value]="value"></app-pci-colour>' })
export class PciColourRendererComponent implements ICellRendererAngularComp {
  value: number;

  agInit(params: ICellRendererParams) {
    this.value = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.agInit(params);
    return true;
  }
}
