<app-header>
  <a mat-flat-button [routerLink]="['/tows', tow._id, 'samples', 'print']">Print</a>
</app-header>

<mat-card>
  <mat-card-header class="flex between">
    <mat-card-title>Sample allocation</mat-card-title>

    @if (permission.size) {
      <div class="flex edit-controls">
        @if (edit) {
          @if (permission.has('allocate')) {
            <button mat-flat-button (click)="onAutoAllocate()">Random Allocation</button>
          }
          <button mat-stroked-button (click)="cancelEdit()">Cancel</button>
          <button mat-flat-button (click)="save()" [disabled]="!changesMade">Save</button>
        } @else if (!tow.metadata.status !== 'finalised') {
          <button mat-flat-button (click)="startEdit()">Edit</button>
        }
      </div>
    }
  </mat-card-header>

  @if (!loadingData && columnDefs && analysts) {
    <mat-card-content>
      <ag-grid-angular
        class="ag-theme-material"
        [class.editable]="edit"
        [class.readonly]="!edit"
        domLayout="autoHeight"
        [gridOptions]="gridOptions"
        [autoSizeStrategy]="autoSizeStrategy"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        rowSelection="multiple"
        suppressRowClickSelection="true"
        (cellEditingStopped)="onCellEditingStopped($event)"
        (gridReady)="onGridReady($event)"
      />
    </mat-card-content>
  }
</mat-card>
