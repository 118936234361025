import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FiltersChannel, IItemsTableOptions, ITableDataSource, SessionService } from '@suvo-bi-lib';
import { TableComponent } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/components/table/table.component';
import { ITablePagination } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-pagination.interface';
import { ITableSort } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { IFilters } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/filters/filters.interface';
import { ISample } from '../../../../../samples/interface/sample.interface';
import { SamplesService } from '../../../../../samples/service/samples.service';
import { UsersService } from '../../../../../users/service/users.service';
import { IRecordingEvent } from '../../../../interface/recording-event.interface';
import { ICprUser } from '../../../../../users/interface/user.interface';

@Component({
  selector: 'app-tow-samples-tile',
  templateUrl: './tow-samples-tile.component.html',
  styleUrls: ['./tow-samples-tile.component.scss'],
})
export class TowSamplesTileComponent extends TableComponent<ISample, string> implements OnInit {
  readonly headings = [
    'sampleNo',
    'userId',
    'chlorophyllIndex',
    'sampleLatitude',
    'sampleLongitude',
    'sampleMidpointDate',
    'button',
  ];

  @Input() tow: IRecordingEvent<'tow'>;

  onlyMySamples: boolean;

  @Input() filtersChannel?: FiltersChannel;
  @Input() options: IItemsTableOptions;
  @Input() dontCache = false;
  @Input() tableAlias: string = 'sample-allocation';

  pageSize = this.options?.defaultPageSize || 999;
  canSort = false;

  defaultSort = { active: 'sampleNo', direction: 'asc' };

  count: number;

  uid: string;
  user: ICprUser;

  constructor(
    sessionService: SessionService,
    private readonly samples: SamplesService,
    private readonly userService: UsersService,
  ) {
    super(sessionService);
  }

  async ngOnInit() {
    this.onlyMySamples = localStorage.getItem('onlyMySamples') == 'true';
    this.user = await this.userService.getMe();
    return super.ngOnInit();
  }

  protected async getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string,
  ): Promise<ITableDataSource<ISample>> {
    let simpleFilters = { towId: `ObjectId:${this.tow._id}` };

    let onlyMySamples = localStorage.getItem('onlyMySamples') == 'true';

    if (onlyMySamples && this.user) {
      simpleFilters['userId'] = `ObjectId:${this.user._id}`;
    }

    const result = await this.samples.getPaginated('', {
      filters,
      sort,
      pagination,
      search,
      simpleFilters: simpleFilters,
    });
    this.count = result.count;
    return result;
  }

  onMySamplesToggle(ev: MatCheckboxChange) {
    localStorage.setItem('onlyMySamples', String(ev.checked));
    this.refresh();
  }
}
