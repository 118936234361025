import { Component, inject, ViewChild } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { ICprUser } from '../../../users/interface/user.interface';
import { UsersService } from '../../../users/service/users.service';
import { UsersModule } from '../../../users/users.module';

@Component({
  standalone: true,
  imports: [MatMenuModule, UsersModule],
  template: `
    <div [matMenuTriggerFor]="menu" (menuClosed)="close()"></div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="value = null; close()">(unassigned)</button>
      @for (analyst of options; track $index) {
        <button mat-menu-item (click)="value = analyst._id; close()">
          <app-user-avatar [userId]="analyst._id" />
        </button>
      }
    </mat-menu>
  `,
})
export class AnalystEditorComponent implements ICellEditorAngularComp {
  private params: ICellEditorParams;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  value: string;

  analysts = inject(UsersService).analysts;
  options: ICprUser[];

  async agInit(params: ICellEditorParams) {
    this.value = params.value;
    this.params = params;
    this.options = await this.analysts;
    this.trigger.openMenu();
  }

  getValue() {
    return this.value;
  }

  close() {
    this.params.api.stopEditing();
  }
}
