<mat-card>
  <mat-card-header>
    <mat-card-title>Tow Log</mat-card-title>
    @if (hasPermission && !cutsGenerated) {
      <div class="controls">
        <button
          mat-stroked-button
          (click)="cancelEdit()"
          class="revert-button"
          [disabled]="!changesMade"
        >
          Revert
        </button>
        <button mat-flat-button (click)="save()" [disabled]="!changesMade" class="save-button">
          Save
        </button>
      </div>
    }
  </mat-card-header>

  <mat-card-content>
    <div class="tow-log-summary">
      <div class="tow-log-summary-column">
        <div>Silk start reading: {{ tow.metadata.silkReadingStart ?? 'Not set' }}</div>
        <div>Silk end reading: {{ tow.metadata.silkReadingEnd ?? 'Not set' }}</div>
      </div>
      <div class="tow-log-summary-column">
        @if (tow.metadata.towMileage) {
          <div>Towed miles: {{ tow.metadata.towMileage }}</div>
        }
        @if (tow.metadata.sampledMileage) {
          <div>Sampled miles: {{ tow.metadata.sampledMileage }}</div>
        }
        @if (tow.metadata.shipSpeed) {
          <div>Average speed: {{ tow.metadata.shipSpeed }}</div>
        }
        @if (tow.metadata.milesPerDivision) {
          <div>Miles per division: {{ tow.metadata.milesPerDivision }}</div>
        }
      </div>
    </div>

    <br />

    @if (!tow.metadata.silkReadingStart || !tow.metadata.silkReadingEnd) {
      <p class="note">
        Note that the silk start reading and silk end reading must be set before the cutting points
        and samples can be generated. These are currently entered on the tow edit screen
        <i>(although they could be editable here in future if preferred)</i>.
      </p>
    }

    <ag-grid-angular
      class="ag-theme-material"
      [class.editable]="edit"
      [class.readonly]="!edit"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [autoSizeStrategy]="autoSizeStrategy"
      [gridOptions]="gridOptions"
      domLayout="autoHeight"
      (cellDoubleClicked)="onDoubleClick($event)"
      (cellEditingStarted)="checkChangesMade()"
      (cellEditingStopped)="checkChangesMade()"
      (cellKeyDown)="onKeyDown($event)"
      (gridReady)="onGridReady($event)"
    />

    @if (edit) {
      <button mat-button style="width: 100%" (click)="insertRow()">
        <mat-icon>add</mat-icon>
        <span>Add an event (Shift + Enter)</span>
      </button>
    }
  </mat-card-content>
</mat-card>
